import React, { useRef, useState } from "react";
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { useEffect } from "react";
import { getUser } from "config/user";
import { COUNTRYINFO } from "data/country";
import { useDispatch, useSelector } from "react-redux";
import { setMapLocation } from "redux/container/userSlice";
import { baseUrl } from "config/apiUrl";
import { setCustomerLocation } from "redux/container/customerSlice";
import { setNewTask } from "redux/container/taskSlice";
import { useNavigate } from "react-router-dom";

Geocode.setApiKey(baseUrl.mapKey);
// Geocode.enableDebug();

function MapView({ location, center, height, zoom, from, to }) {
	let user = getUser();
	let _u = localStorage.getItem("setUser");
	let userParse = JSON.parse(_u)?.user;
	let dispatch = useDispatch();
	let navigate = useNavigate();
	let autocompleteRef = useRef(null);
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [area, setArea] = useState("");
	const [state, setState] = useState("");

	console.log(address);

	const [mapPosition, setMapPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});
	const [markerPosition, setMarkerPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});

	useEffect(() => {
		Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);

				// console.log("city", city, area, state);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				dispatch(setNewTask({ address: address }));
				dispatch(setNewTask({ city: city }));
				dispatch(setNewTask({ address: area }));
				dispatch(setNewTask({ state: state }));
			},
			(error) => {
				console.error(error);
			}
		);
	}, []);

	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_2" === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					"administrative_area_level_1" === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	function handleClick(event) {
		var newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				setMapPosition({
					lat: newLat,
					lng: newLng,
				});
				setMarkerPosition({
					lat: newLat,
					lng: newLng,
				});
				let locationBuilder = {
					city,
					area,
					state,
					lat: newLat,
					lng: newLng,
					address: `${city} ${area} ${state}`,
				};
			},
			(error) => {
				console.error(error);
			}
		);
	}

	const AsyncMap = withScriptjs(
		withGoogleMap((props) => (
			<GoogleMap
				// google={this.props.google}
				onClick={(e) => handleClick(e)}
				onRightClick={props.onMapClick}
				defaultZoom={zoom}
				defaultOptions={{ fullscreenControl: false, mapTypeControl: false }}
				defaultCenter={{
					lat: mapPosition.lat,
					lng: mapPosition.lng,
				}}>
				{/*Marker*/}
				<Marker
					icon={{
						url: require("assets/images/icon.png"),
					}}
					position={{
						lat: Number(location?.lat),
						lng: Number(location?.lng),
					}}
				/>
			</GoogleMap>
		))
	);

	let map;
	if (center.lat !== undefined) {
		map = (
			<div>
				<p className='pb-4 font-bold text-14'>
					{area}
					{", "}
					{city}
					{", "}
					{state}
				</p>
				<AsyncMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${baseUrl.mapKey}&libraries=places`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: height }} />}
					mapElement={<div style={{ height: `100%` }} />}
				/>
			</div>
		);
	} else {
		map = (
			<>
				<div style={{ height: height }} />
			</>
		);
	}
	return map;
}
export default MapView;
