import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MapView from "../LocationPicker/MapView";

export default function TaskDetailsPublic({ loading, singleBlog }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let checkStatus = (status) => {
		if (status === "Completed") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-F27900'
					style={{ borderRadius: "0" }}>
					<p className='text-F27900'>{ekkLanguage.task.completed}</p>
				</div>
			);
		} else if (status === "Active") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-success'
					style={{ borderRadius: "0" }}>
					<p className='text-success'>{ekkLanguage.task.onGoing}</p>
				</div>
			);
		} else if (status === "Cancelled") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-primary'
					style={{ borderRadius: "0" }}>
					<p className='text-primary'>{ekkLanguage.task.cancelled}</p>
				</div>
			);
		} else {
			return "";
		}
	};

	return (
		<>
			{!loading ? (
				<div className='pt-[100px] sm:pt-[120px] pb-24'>
					<div className='container relative'>
						<div className='m-auto w-full lg:w-8/12 relative'>
							{checkStatus(singleBlog?.status)}
							{singleBlog?.images?.length ? (
								<div className='h-[350px] overflow-hidden mb-8'>
									<img
										src={singleBlog?.images[0]}
										alt='blogImage'
										className='object-cover w-full h-full'
									/>
								</div>
							) : (
								<div className='h-[350px] overflow-hidden mb-8'>
									<img
										src='/assets/images/logo.png'
										alt='blogImage'
										className='w-[80%]'
									/>
								</div>
							)}
							<div>
								<h2 className='lg:text-40 md:text-32 text-22 text-black-1000 font-bold pb-3'>
									{singleBlog?.title}
								</h2>
								<div className='flex justify-between py-4'>
									<div>
										<div className='flex items-center'>
											<h6 className='font-bold text-808080 text-12'>
												{ekkLanguage.task.taskStart}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														monthChanger(
															moment(
																singleBlog?.start_date
															).format("DD MMM, YY")
														)
													)}
												</span>
											</div>
										</div>{" "}
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.taskEnd}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														monthChanger(
															moment(
																singleBlog?.end_date
															).format("DD MMM, YY")
														)
													)}
												</span>
											</div>
										</div>
									</div>
									<div>
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.position}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														singleBlog?.required_volunteer
													)}
												</span>
											</div>
										</div>
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.jointed}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														singleBlog?.enrolled_volunteer
													)}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-6 flex justify-between items-center'>
									<div className='flex'>
										<img
											className='w-10 h-10 mr-2'
											src={`${
												singleBlog?.creator?.image
													? singleBlog?.creator?.image
													: "/assets/images/avatar.png"
											}`}
											alt=''
										/>
										<div>
											<h6 className='text-14 font-bold'>
												{singleBlog?.creator?.name}
											</h6>
											<p className='text-808080 text-12'>
												Organizer
											</p>
											{/* <p className='text-808080 text-12'>
											Sun Francisco
										</p> */}
										</div>
									</div>
								</div>

								<p className='text-12 sm:text-16 font-normal text-808080 pb-5'>
									{singleBlog?.description}{" "}
								</p>
								<div>
									<h5 className='text-18 font-bold mb-3'>Location</h5>
									<div>
										{singleBlog?.latitude && singleBlog?.longitude ? (
											<MapView
												center={{
													lat: singleBlog?.latitude,
													lng: singleBlog?.longitude,
												}}
												height='400px'
												zoom={18}
												location={{
													lat: singleBlog?.latitude,
													lng: singleBlog?.longitude,
												}}
											/>
										) : (
											<p>Map is not enabled for this task</p>
										)}
									</div>
								</div>
								{singleBlog?.comments?.length ? (
									<div className='py-3 pt-5'>
										<h5 className='font-bold mb-3'>
											{ekkLanguage.learning.comments}
										</h5>
										<div className='py-3 bg-white px-4'>
											{singleBlog?.comments?.length &&
												singleBlog?.comments?.map((comment, i) => {
													return (
														<div
															key={comment.id}
															className='mt-[15px] flex '>
															<img
																className='w-10 h-10 rounded-full'
																src={
																	comment?.thumbnail_image
																		? comment?.thumbnail_image
																		: "/assets/images/avatar.png"
																}
																alt=''
															/>
															<div className='flex flex-col ml-[10px]'>
																<div className='flex w-full'>
																	<p className='text-14 font-bold text-[#000] '>
																		{comment?.author}{" "}
																		<span className='text-14 font-bold text-[#00000080]'>
																			<span className='px-0.5'>
																				|{" "}
																			</span>
																			{digitChanger(
																				monthChanger(
																					moment(
																						comment?.created_at
																					).format(
																						"DD MMM, YY"
																					)
																				)
																			)}
																			<span className='px-0.5'>
																				|{" "}
																			</span>
																			{digitChanger(
																				monthChanger(
																					moment(
																						comment?.created_at
																					).format(
																						"h:mm a"
																					)
																				)
																			)}
																		</span>
																	</p>
																</div>
																<p className='text-12 font-semibold text-[#222] text-justify'>
																	{comment?.content}
																</p>
																{comment?.images?.length ? (
																	<div className='pt-3'>
																		{comment?.images?.map(
																			(el, i) => {
																				return (
																					<img
																						className='mb-2 rounded-[8px] max-h-[400px] m-auto'
																						src={el}
																						alt='images'
																					/>
																				);
																			}
																		)}
																	</div>
																) : undefined}
															</div>
														</div>
													);
												})}
										</div>
									</div>
								) : undefined}

								{singleBlog?.status !== "Cancelled" && (
									<div className='text-center pt-14 lg:mb-0 mb-10 pb-5'>
										<Link
											to='/'
											className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}>
											{ekkLanguage.task.joinTask}
										</Link>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='pt-[100px] sm:pt-[150px] pb-24'>
					<div className='m-auto w-full lg:w-8/12'>
						<div className='flex h-full justify-center items-center'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
