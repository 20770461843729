import { getLocalUser, getUser } from "config/user";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPostList } from "redux/container/learningSlice";
import Blog from "services/Blog";
import Share from "./Share";
import Task from "services/Task";

export default function Tasks({ learningList, ekkLanguage }) {
	const [showAnim, setShowAnim] = useState({});
	const navigate = useNavigate();
	const localUser = getUser();
	const user = getLocalUser();
	const dispatch = useDispatch();
	const [showShareOptions, setShowShareOptions] = useState(false);
	const [taskId, setTaskId] = useState("");

	useEffect(() => {
		const animationCSS = `
		  @keyframes myAnimation {
			0% {
			  transform: translate(0, 0) scale(1);
			}
			50% {
			  transform: translate(2px, 2px) scale(1.1);
			}
			100% {
			  transform: translate(0, 0) scale(1);
			}
		  }`;

		const styleElement = document.createElement("style");
		styleElement.textContent = animationCSS;

		document.head.appendChild(styleElement);
	}, []);

	const userLikeCheck = (learningDetails) => {
		if (learningDetails?.bazzed_users?.length > 0) {
			for (const bazz of learningDetails?.bazzed_users) {
				if (localUser !== null) {
					if (bazz == localUser?._id) {
						return true;
					}
				} else {
					if (bazz == user?.user?._id) {
						return true;
					}
				}
			}
		} else {
			return false;
		}
	};

	const handleLike = async (post) => {
		let response = await Blog.addLike(post?.id);
		if (response.status === 200) {
			setShowAnim({ show: true, id: post?.id });
			const likedPostList = learningList.map((element) =>
				element.id === post?.id
					? {
							...element,
							bazz_count: element.bazz_count + 1,
							bazzed_users: [
								...element.bazzed_users,
								localUser?._id ?? user?.user?._id,
							],
					  }
					: element
			);
			dispatch(setPostList(likedPostList));
		}
	};

	const taskJoinHandler = async (task) => {
		let payload = {
			name: localUser?.DisplayName ?? user?.user?.DisplayName,
			phone: localUser?.PhoneNumber ?? user?.user?.PhoneNumber,
			role: "Participant",
			user_id: localUser?._id ?? user?.user?._id,
		};
		let response = await Task.joinTask(task?.id, payload);
		if (response.status === 200) {
			toast.success("Successfully joined");
		} else {
			toast.error("Joined failed");
		}
	};

	let checkStatus = (status) => {
		if (status === "Completed") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-F27900'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-F27900'>{ekkLanguage.task.completed}</p>
				</div>
			);
		} else if (status === "Active") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-success'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-success'>{ekkLanguage.task.onGoing}</p>
				</div>
			);
		} else if (status === "Cancelled") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-primary'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-primary'>{ekkLanguage.task.cancelled}</p>
				</div>
			);
		} else {
			return "";
		}
	};
	return (
		<>
			{/* All Post Array Map  */}
			{learningList?.length > 0 ? (
				learningList.map((task) => {
					const contentChecker = (txt) => {
						if (txt) {
							if (txt?.length > 73) {
								return txt?.substring(0, 70) + "...";
							} else {
								return txt;
							}
						} else {
							return "";
						}
					};

					return (
						<div
							onClick={() => navigate(`/task-details/${task?.id}`)}
							key={task?.id}
							className='py-3 bg-white px-4 mb-3 relative'>
							{task?.images?.length ? (
								<div className='rounded-[8px] mb-[10px] border relative'>
									{checkStatus(task?.status)}

									<img
										src={task?.images[0]}
										className='w-full rounded-[5px]'
										alt='slideImage'
									/>
								</div>
							) : (
								<div className='rounded-[8px] mb-[10px] border relative  h-[200px] flex items-center'>
									{checkStatus(task?.status)}
									<img
										src='/assets/images/logo.png'
										className='w-[220px] m-auto rounded-[5px]'
										alt='slideImage'
									/>
								</div>
							)}
							{/* Title  */}
							<h6 className='font-bold text-16 mb-[5px]'>
								{task.title}
								{/* {learn?.tags?.length > 4 && <br />} */}
								{/* {learn.tags &&
									learn.tags.length > 0 &&
									learn.tags.map((tag, index) => {
										return (
											<span
												key={index}
												className='text-delivered px-0.5'>
												#{tag}
											</span>
										);
									})} */}
							</h6>
							{/* Created Date  */}

							<div className='flex justify-between py-4'>
								<div>
									<div className='flex items-center'>
										<h6 className='font-bold text-808080 text-12'>
											{ekkLanguage.task.taskStart}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													monthChanger(
														moment(task?.start_date).format(
															"DD MMM, YY"
														)
													)
												)}
											</span>
										</div>
									</div>{" "}
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.taskEnd}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													monthChanger(
														moment(task?.end_date).format(
															"DD MMM, YY"
														)
													)
												)}
											</span>
										</div>
									</div>
								</div>

								<div>
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.position}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(task?.required_volunteer)}
											</span>
										</div>
									</div>
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.jointed}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(task?.enrolled_volunteer)}
											</span>
										</div>
									</div>
								</div>
							</div>
							{/* <div className='flex justify-between py-4'>
								<div className='flex items-center'>
									<h6 className='font-bold text-14'>Event Start: </h6>
									<div>
										<span className='text-808080 font-semibold text-12'>
											{digitChanger(
												monthChanger(
													moment(learn.createdAt).format(
														"DD MMM, YY"
													)
												)
											)}
										</span>
									</div>
								</div>{" "}
								<div className='flex items-center'>
									<h6 className='font-bold text-14'>Event End: </h6>
									<div>
										<span className='text-808080 font-semibold text-12'>
											{digitChanger(
												monthChanger(
													moment(learn.createdAt).format(
														"DD MMM, YY"
													)
												)
											)}
										</span>
									</div>
								</div>
							</div> */}
							{/* Content  */}
							<p
								onClick={() => navigate(`/task-details/${task?.id}`)}
								className='text-14 font-medium text-222222 text-justify flex justify-between'>
								<span>{contentChecker(task?.description)}</span>
							</p>
							<div className='flex justify-end'>
								<span
									className='text-delivered cursor-pointer '
									onClick={() =>
										navigate(`/task-details/${task?.id}`)
									}>
									{ekkLanguage.landing.seeMore}
								</span>
							</div>

							{/* Like & Comment Icon Section  */}
							<div className='flex items-center justify-between py-[2px]'>
								{/* <div className='flex items-center'>
									{!userLikeCheck(task) ? (
										<button
											className='flex items-center'
											onClick={() => {
												handleLike(task);
											}}>
											<img
												src='/assets/images/like.png'
												alt='icon'
												className='mr-[7px] w-[26px] h-[26px]'
											/>
											<h6 className='text-18 font-semibold pt-2'>
												{digitChanger(task?.bazz_count ?? 0)}
											</h6>
										</button>
									) : (
										<button
											className='flex items-center'
											onClick={() =>
												toast.error(
													ekkLanguage.learning.likedWarning
												)
											}>
											<img
												style={{
													animation: `${
														showAnim.show &&
														showAnim.id === task?.id &&
														"myAnimation 1s ease-in-out"
													}`,
													animationPlayState: `${
														showAnim.show &&
														showAnim.id === task?.id &&
														"running"
													}`,
												}}
												src='/assets/images/liked.png'
												alt='icon'
												className='mr-[7px] w-[26px] h-[26px]'
											/>
											<h6 className='text-18 font-semibold pt-2'>
												{digitChanger(task.bazz_count ?? 0)}
											</h6>
										</button>
									)}
								</div>

								<div className='flex items-center'>
									<img
										onClick={() =>
											navigate(`/task-details/${task.id}`)
										}
										src='assets/images/message.png'
										alt='icon'
										className='mr-[7px]'
									/>
									<span className='text-16 font-semibold'>
										{digitChanger(task?.comments?.length ?? 0)}
									</span>
								</div> */}

								{/* <div className='text-center'>
									<button
										onClick={() => taskJoinHandler(task)}
										className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] pwa-download-btn`}>
										{ekkLanguage.task.joinTask}
									</button>
								</div>
								<div>
									<img
										onClick={() => {
											setTaskId(task.id);
											setShowShareOptions(!showShareOptions);
										}}
										className='w-5 h-5'
										src='/assets/images/Icon/share.png'
										alt=''
									/>

									{showShareOptions && taskId === task.id && (
										<Share
											ekkLanguage={ekkLanguage}
											id={task?.id}
											setShowShareOptions={setShowShareOptions}
											showShareOptions={showShareOptions}
										/>
									)}
								</div> */}
							</div>
						</div>
					);
				})
			) : (
				<div
					className='text-center w-full  flex items-center justify-center'
					style={{ height: "calc(100vh - 420px)" }}>
					<div>
						<img
							className='m-auto mb-[10px] mt-5'
							src='/assets/images/not-found-file.png'
							alt='emptyCard'
						/>
						<p className='text-16 font-normal text-222222 mb-[35px]'>
							{ekkLanguage.learning.notFound}
						</p>
					</div>
				</div>
			)}
		</>
	);
}
