import {
	IonApp,
	IonContent,
	IonLoading,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import Comment from "assets/images/icon/svg/Comment";
import TaskUpdateImage from "components/global-components/common/TaskUpdateImage";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import MapView from "components/mobile-components/LocationPicker/MapView";
import Share from "components/mobile-components/Task/Share";
import { getLocalUser, getUser } from "config/user";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Task from "services/Task";
import User from "services/User";

export default function TaskDetailsPage() {
	const [loading, setLoading] = useState(true);
	const [learningDetails, setLearningDetails] = useState({});
	const { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [comment, setComment] = useState("");
	const [error, setError] = useState("");
	const [messageLoader, setMessageLoader] = useState(false);
	const [files, setFiles] = useState([]);

	const localUser = getUser();
	const user = getLocalUser();
	const [showShareOptions, setShowShareOptions] = useState(false);
	const [taskId, setTaskId] = useState("");

	const getLearningDetails = async () => {
		let response = await Task.getTask(id);
		if (response.status === 200) {
			setLoading(false);
			setLearningDetails(response?.results);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getLearningDetails();
	}, []);

	const taskJoinHandler = async (task) => {
		let payload = {
			name: localUser?.DisplayName ?? user?.user?.DisplayName,
			phone: localUser?.PhoneNumber ?? user?.user?.PhoneNumber,
			role: "Participant",
			user_id: localUser?._id ?? user?.user?._id,
		};
		let response = await Task.joinTask(task?.id, payload);
		if ((response?.status === 201) | (response?.status === 200)) {
			getLearningDetails();
			toast.success(ekkLanguage.task.successJoint);
		} else {
			toast.error(ekkLanguage.task.failedJoint);
		}
	};
	const completeHandler = async (taskId, status) => {
		let payload = {
			status,
		};
		let response = await Task.completeTask(taskId, payload);

		if (response.status === 200) {
			getLearningDetails();
			toast.success("Successfully completed");
		} else {
			toast.error(ekkLanguage.task.failedJoint);
		}
	};

	const inviteHandler = async (taskId, userId) => {
		let payload = {
			task_id: taskId,
			user_id: localUser?._id ?? user?.user?._id,
		};
		let response = await Task.inviteTask(payload);

		if (response.status === 201) {
			getLearningDetails();
			toast.success(ekkLanguage.task.successInvite);
		} else {
			toast.error(ekkLanguage.task.inviteFailed);
		}
	};

	let checkStatus = (status) => {
		if (status === "Completed") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-F27900'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-F27900'>{ekkLanguage.task.completed}</p>
				</div>
			);
		} else if (status === "Active") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-success'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-success'>{ekkLanguage.task.onGoing}</p>
				</div>
			);
		} else if (status === "Cancelled") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-primary'
					style={{ borderRadius: "5px 0 5px" }}>
					<p className='text-primary'>{ekkLanguage.task.cancelled}</p>
				</div>
			);
		} else {
			return "";
		}
	};

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}
	const handleComment = async () => {
		if (files?.length) {
			const blobsArray = files.map((el) => DataURIToBlob(el.preview));
			const imageUrls = [];
			for (const [index, blob] of blobsArray.entries()) {
				const imageData = new FormData();
				imageData.append("key", blob, `image-${index}.png`);
				try {
					const uploadResponse = await User.toBucketImage(imageData);
					imageUrls.push(uploadResponse?.results[0]);
				} catch (error) {
					console.error(`Error uploading image-${index}:`, error);
				}
			}
			if (imageUrls?.length) {
				setMessageLoader(true);
				let payload = {
					author: localUser?.DisplayName
						? localUser?.DisplayName
						: user?.user?.DisplayName,
					task: learningDetails.id,
					user_id: localUser?._id ? localUser._id : user?.user?._id,
					content: comment,
					images: imageUrls,
				};
				let response = await Task.addComment(payload);
				if (response.status === 201) {
					setFiles([]);
					setComment("");
					setError("");
					getLearningDetails();
					setMessageLoader(false);
				} else {
					setMessageLoader(false);
				}
			} else {
				if (comment !== "") {
					setMessageLoader(true);
					let payload = {
						author: localUser?.DisplayName
							? localUser?.DisplayName
							: user?.user?.DisplayName,
						task: learningDetails.id,
						user_id: localUser?._id ? localUser._id : user?.user?._id,
						content: comment,
					};
					let response = await Task.addComment(payload);
					if (response.status === 201) {
						setComment("");
						setError("");
						getLearningDetails();
						setMessageLoader(false);
					} else {
						setMessageLoader(false);
					}
				} else {
					setError(ekkLanguage.task?.doaComment);
				}
			}
		} else {
			if (comment !== "") {
				setMessageLoader(true);
				let payload = {
					author: localUser?.DisplayName
						? localUser?.DisplayName
						: user?.user?.DisplayName,
					task: learningDetails.id,
					user_id: localUser?._id ? localUser._id : user?.user?._id,
					content: comment,
				};
				let response = await Task.addComment(payload);
				if (response.status === 201) {
					setComment("");
					setError("");
					getLearningDetails();
					setMessageLoader(false);
				} else {
					setMessageLoader(false);
				}
			} else {
				setError(ekkLanguage.task?.doaComment);
			}
		}
	};
	let userId = localUser?._id ? localUser._id : user?.user?._id;
	let listChecking = learningDetails?.volunteer?.find(
		(el) => el.user_id === userId && el.status === "Pending"
	);
	let listInviteChecking = learningDetails?.volunteer?.find(
		(el) => el.user_id === userId && el.status === "Invited"
	);
	let acceptedChecking = learningDetails?.volunteer?.find(
		(el) => el.user_id === userId && el.status === "Accepted"
	);

	const statsBasedBtn = () => {
		if (!learningDetails?.own_status) {
			return (
				<div className='text-center'>
					<button
						onClick={() => taskJoinHandler(learningDetails)}
						className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] pwa-download-btn`}>
						{ekkLanguage.task.joinTask}
					</button>
				</div>
			);
		} else {
			if (listChecking?.id) {
				return (
					<div className='text-center'>
						<button
							className={`text-white text-14 font-bold px-5 py-2 rounded-[10px] pwa-download-btn`}>
							{ekkLanguage.task.requestPending}
						</button>
					</div>
				);
			} else {
				if (listInviteChecking?.id) {
					return (
						<div className='flex'>
							<div className='text-center mr-1'>
								<button
									onClick={() =>
										participantsHandler(
											learningDetails?.id,
											listInviteChecking?.id,
											"Accepted"
										)
									}
									className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] bg-success`}>
									{ekkLanguage.task.accept}
								</button>
							</div>

							<div className='text-center'>
								<button
									onClick={() =>
										participantsHandler(
											learningDetails?.id,
											listInviteChecking?.id,
											"Rejected"
										)
									}
									className={`pointer bg-primary text-white text-14 font-bold px-5 py-2 rounded-[10px]`}>
									{ekkLanguage.task.cancel}
								</button>
							</div>
						</div>
					);
				} else {
					return (
						<>
							{learningDetails?.status === "Active" &&
								learningDetails?.own_role === "Creator" && (
									<div className='flex'>
										<div className='text-center mr-1'>
											<button
												onClick={() =>
													completeHandler(
														learningDetails?.id,
														"Completed"
													)
												}
												className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] pwa-download-btn`}>
												{ekkLanguage.task.successEnd}
											</button>
										</div>
										{!learningDetails?.is_invited && (
											<div className='text-center mr-1'>
												<button
													onClick={() =>
														inviteHandler(learningDetails?.id)
													}
													className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] bg-success`}>
													{ekkLanguage.task.invite}
												</button>
											</div>
										)}

										<div className='text-center'>
											<button
												onClick={() =>
													completeHandler(
														learningDetails?.id,
														"Cancelled"
													)
												}
												className={`pointer bg-primary text-white text-14 font-bold px-5 py-2 rounded-[10px]`}>
												{ekkLanguage.task.cancel}
											</button>
										</div>
									</div>
								)}
						</>
					);
				}
			}
		}
	};

	const participantsHandler = async (taskId, volunteerId, status) => {
		let payload = {
			task_id: taskId,
			volunteer_id: volunteerId,
			status,
		};
		let response = await Task.acceptReject(payload);

		if ((response.status === 201, response.status === 200)) {
			getLearningDetails();
			if (status === "Accepted") {
				toast.success(ekkLanguage.task.successAccepted);
			} else {
				toast.success(ekkLanguage.task.successRejected);
			}
		} else {
			toast.error(ekkLanguage.task.failed);
		}
	};
	const stopMapRender = useMemo(() => {
		return (
			<div className='pt-3'>
				<h5 className='text-18 font-bold mb-3'>Location</h5>
				<div className='p-3 bg-white'>
					{learningDetails?.latitude && learningDetails?.longitude ? (
						<MapView
							center={{
								lat: learningDetails?.latitude,
								lng: learningDetails?.longitude,
							}}
							height='300px'
							zoom={18}
							location={{
								lat: learningDetails?.latitude,
								lng: learningDetails?.longitude,
							}}
						/>
					) : (
						<p>Map is not enabled for this task</p>
					)}
				</div>
			</div>
		);
	}, [learningDetails]);
	const imageRemover = (el) => {
		const filteredArray = files.filter((item) => item.preview !== el.preview);
		setFiles(filteredArray);
	};

	return (
		<IonApp style={{ "--ion-background-color": "#E6E5E5" }}>
			<IonPage>
				<IonContent scrollEvents={true}>
					<BackButtonTitle title={ekkLanguage.task?.taskDetails} />
					{loading && <IonLoading isOpen={true} />}
					<section className='pt-14 mx-2'>
						<div className='py-3 bg-white px-4 mb-[6px] relative my-2'>
							{/* Image Section  */}

							{learningDetails?.images?.length ? (
								<div className='rounded-[8px] mb-[10px] border relative'>
									{checkStatus(learningDetails?.status)}

									<img
										src={learningDetails?.images[0]}
										className='w-full rounded-[5px]'
										alt='slideImage'
									/>
								</div>
							) : (
								<div className='rounded-[8px] mb-[10px] border relative  h-[200px] flex items-center'>
									{checkStatus(learningDetails?.status)}
									<img
										src='/assets/images/logo.png'
										className='w-[220px] m-auto rounded-[5px]'
										alt='slideImage'
									/>
								</div>
							)}

							{/* Title  */}
							<h6 className='font-bold text-16 mb-[5px]'>
								{learningDetails.title}
								{/* {learn?.tags?.length > 4 && <br />} */}
								{/* {learn.tags &&
									learn.tags.length > 0 &&
									learn.tags.map((tag, index) => {
										return (
											<span
												key={index}
												className='text-delivered px-0.5'>
												#{tag}
											</span>
										);
									})} */}
							</h6>
							{/* Created Date  */}
							<div className='flex justify-between py-4'>
								<div>
									<div className='flex items-center'>
										<h6 className='font-bold text-808080 text-12'>
											{ekkLanguage.task.taskStart}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													monthChanger(
														moment(
															learningDetails?.start_date
														).format("DD MMM, YY")
													)
												)}
											</span>
										</div>
									</div>{" "}
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.taskEnd}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													monthChanger(
														moment(
															learningDetails?.end_date
														).format("DD MMM, YY")
													)
												)}
											</span>
										</div>
									</div>
								</div>
								<div>
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.position}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													learningDetails?.required_volunteer
												)}
											</span>
										</div>
									</div>
									<div className='flex items-center'>
										<h6 className='font-bold text-12 text-808080'>
											{ekkLanguage.task.jointed}:{" "}
										</h6>
										<div>
											<span className='text-black-1000 font-semibold text-12'>
												{" "}
												{digitChanger(
													learningDetails?.enrolled_volunteer
												)}
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className='mb-4 flex justify-between items-center'>
								<div className='flex'>
									<img
										className='w-10 h-10 mr-2 rounded-full'
										src={`${
											learningDetails?.creator?.image
												? learningDetails?.creator?.image
												: "/assets/images/avatar.png"
										}`}
										alt=''
									/>
									<div>
										<h6 className='text-14 font-bold'>
											{learningDetails?.creator?.name}
										</h6>
										<p className='text-808080 text-12'>Organizer</p>
										{/* <p className='text-808080 text-12'>
											Sun Francisco
										</p> */}
									</div>
								</div>
							</div>

							<p
								// onClick={() =>
								// 	navigate(`/learning-details/${learningDetails?.id}`)
								// }
								className='text-14 font-medium text-222222 text-justify mb-5'>
								{learningDetails?.description}
							</p>
							<div className={`flex justify-between items-center`}>
								<div className='fle'>{statsBasedBtn()}</div>
								{learningDetails?.status !== "Cancelled" && (
									<div>
										<img
											onClick={() => {
												setTaskId(learningDetails.id);
												setShowShareOptions(!showShareOptions);
											}}
											className='w-5 h-5'
											src='/assets/images/Icon/share.png'
											alt=''
										/>

										{showShareOptions &&
											taskId === learningDetails?.id && (
												<Share
													ekkLanguage={ekkLanguage}
													id={learningDetails?.id}
													setShowShareOptions={setShowShareOptions}
													showShareOptions={showShareOptions}
												/>
											)}
									</div>
								)}
							</div>
						</div>
						{stopMapRender}
						{learningDetails?.comments?.length ? (
							<div className='py-3 pt-5'>
								<h5 className='font-bold mb-3'>
									{ekkLanguage.learning.comments}
								</h5>
								<div className='py-3 bg-white px-4'>
									{learningDetails?.comments?.length &&
										learningDetails?.comments?.map((comment, i) => {
											return (
												<div
													key={comment.id}
													className='mt-[15px] flex '>
													<img
														className='w-10 h-10 rounded-full'
														src={
															comment?.thumbnail_image
																? comment?.thumbnail_image
																: "/assets/images/avatar.png"
														}
														alt=''
													/>
													<div className='flex flex-col ml-[10px]'>
														<div className='flex w-full'>
															<p className='text-14 font-bold text-[#000] '>
																{comment?.author}{" "}
																<span className='text-14 font-bold text-[#00000080]'>
																	<span className='px-0.5'>
																		|{" "}
																	</span>
																	{digitChanger(
																		monthChanger(
																			moment(
																				comment?.created_at
																			).format("DD MMM, YY")
																		)
																	)}
																	<span className='px-0.5'>
																		|{" "}
																	</span>
																	{digitChanger(
																		monthChanger(
																			moment(
																				comment?.created_at
																			).format("h:mm a")
																		)
																	)}
																</span>
															</p>
														</div>
														<p className='text-12 font-semibold text-[#222] text-justify'>
															{comment?.content}
														</p>
														{comment?.images?.length ? (
															<div className='pt-3'>
																{comment?.images?.map(
																	(el, i) => {
																		return (
																			<img
																				className='mb-2 rounded-[8px] max-h-[400px] m-auto'
																				src={el}
																				alt='images'
																			/>
																		);
																	}
																)}
															</div>
														) : undefined}
													</div>
												</div>
											);
										})}
								</div>
							</div>
						) : undefined}

						<div className='py-4' style={{ paddingBottom: "80px" }}>
							<h5 className='font-bold'>
								{ekkLanguage.task.participant}
							</h5>
							<div className='pt-3'>
								{learningDetails?.volunteer?.length &&
								learningDetails?.own_role === "Creator" ? (
									<>
										{learningDetails?.volunteer?.map((el, i) => {
											return (
												<div
													className='bg-white px-3 py-3 rounded-[5px] mb-3 flex justify-between'
													style={{
														boxShadow: "0 1px 4px rgba(0,0,0,.2)",
													}}>
													<div>
														<h4 className='font-bold text-14'>
															{el?.name}{" "}
															<span className='font-normal text-10 text-black-500'>
																{el?.role}
															</span>
														</h4>
														{learningDetails?.own_role ===
														"Creator" ? (
															<>
																<p className='font-normal text-10 text-black-1000'>
																	{el?.phone}
																</p>
																{learningDetails?.own_role ===
																"Creator" ? (
																	<p
																		className={`text-12 ${
																			el?.status ===
																			"Rejected"
																				? "text-primary"
																				: "text-success"
																		}`}>
																		{" "}
																		{el?.status}
																	</p>
																) : undefined}
															</>
														) : undefined}
													</div>
													{el.status === "Pending" ? (
														<div className='flex'>
															<div className='text-center mr-1'>
																<button
																	onClick={() =>
																		participantsHandler(
																			learningDetails?.id,
																			el?.id,
																			"Accepted"
																		)
																	}
																	className={`pointer text-white text-14 font-bold px-5 py-2 rounded-[10px] bg-success`}>
																	{ekkLanguage.task.accept}
																</button>
															</div>

															<div className='text-center'>
																<button
																	onClick={() =>
																		participantsHandler(
																			learningDetails?.id,
																			el?.id,
																			"Rejected"
																		)
																	}
																	className={`pointer bg-primary text-white text-14 font-bold px-5 py-2 rounded-[10px]`}>
																	{ekkLanguage.task.cancel}
																</button>
															</div>
														</div>
													) : undefined}
												</div>
											);
										})}
									</>
								) : (
									<>
										{learningDetails?.volunteer
											?.filter((el) => el.status == "Accepted")
											?.map((el, i) => {
												return (
													<div
														className='bg-white px-3 py-3 rounded-[5px] mb-3'
														style={{
															boxShadow:
																"0 1px 4px rgba(0,0,0,.2)",
														}}>
														<h4 className='font-bold text-14'>
															{el?.name}{" "}
															<span className='font-normal text-10 text-black-500'>
																{el?.role}
															</span>
														</h4>
														{learningDetails?.own_role ===
														"Creator" ? (
															<p className='font-normal text-10 text-black-1000'>
																{el?.phone}
															</p>
														) : undefined}
													</div>
												);
											})}
									</>
								)}
							</div>
						</div>
						{/* Write Comment  */}
						{learningDetails?.own_status &&
						(listChecking?.status === "Accepted") |
							(listInviteChecking?.status === "Accepted") |
							(acceptedChecking?.status === "Accepted") ? (
							<div
								className='fixed  left-0 bottom-0 w-full px-4 bg-white py-3'
								style={{ borderTop: "1px solid rgba(0, 0, 0, .1)" }}>
								{files?.length ? (
									<div>
										<h5 className='font-bold text-14 pb-2'>
											Selected image for upload
										</h5>
										<div className='flex flex-wrap'>
											{files?.map((el, i) => {
												return (
													<div className='relative'>
														<img
															key={i}
															className='mx-1 mb-3 border rounded-[4px] max-h-[100px]'
															src={el?.preview}
															alt='images'
														/>
														<span
															className='absolute top-1 right-2 flex bg-primary p-1 rounded-full deselect-image'
															onClick={() => imageRemover(el)}>
															<ion-icon
																size='small'
																name='trash-outline'></ion-icon>
														</span>
													</div>
												);
											})}
										</div>
									</div>
								) : undefined}

								<div className='flex items-center w-full bg-white '>
									<div className='flex items-center relative w-[calc(100%-30px)]'>
										<input
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													handleComment();
												}
											}}
											onChange={(e) => setComment(e.target.value)}
											value={comment}
											type='text'
											placeholder={`${ekkLanguage.learning.commentAs} `}
											className='py-3 pr-10 px-3 appearance-none block w-full border rounded-[47px] mr-4 leading-tight focus:outline-1 bg-[#E6E6E6] focus:bg-white text-14'
										/>
										{messageLoader ? (
											<button className='absolute right-[20px]'>
												<IonSpinner color='success'></IonSpinner>
											</button>
										) : (
											<button
												className='absolute right-[20px]'
												onClick={handleComment}>
												<Comment />
											</button>
										)}
									</div>

									<TaskUpdateImage files={files} setFiles={setFiles} />
								</div>
							</div>
						) : undefined}
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
