import Pen from "assets/images/icon/svg/Pen";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import User from "services/User";
import { IonSpinner } from "@ionic/react";

const TaskUpdateImage = ({ files, setFiles, name = "image" }) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);

	console.log(files);

	useEffect(() => {
		const uploadFile = async () => {
			// if (files.length !== 0) {
			// 	setLoading(true);
			// 	let formData = new FormData();
			// 	formData.append("key", files[0]);
			// 	try {
			// 		let response = await User.toBucketImage(formData);
			// 		if (response.status === 201 || response.status === 200) {
			// 			var reqbody = {
			// 				profile_image: response?.results[0],
			// 			};
			// 		}
			// 	} catch (error) {
			// 		console.error("Error uploading file:", error);
			// 	}
			// }
		};

		uploadFile();
	}, [files]);
	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: async (acceptedFiles) => {
			const newFiles = await Promise.all(
				acceptedFiles.map(async (file) => {
					const base64 = await convertToBase64(file);
					return { path: file.name, preview: base64 };
				})
			);
			setFiles((prevFiles) => [...prevFiles, ...newFiles]);
			// setFiles(
			// 	acceptedFiles.map((file) =>
			// 		Object.assign(file, {
			// 			preview: URL.createObjectURL(file),
			// 		})
			// 	)
			// );
		},
	});

	return (
		<section>
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				{loading && (
					<div className='absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 rounded-full'>
						<IonSpinner />
					</div>
				)}
				{!loading && (
					<div className='image-uploader'>
						<ion-icon name='images-outline'></ion-icon>
					</div>
				)}
			</div>
		</section>
	);
};

export default TaskUpdateImage;
