import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SingleTask({ item }) {
	const navigate = useNavigate();
	let content = item?.description
		? item?.description?.substring(0, 33) + "..."
		: "";
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let checkStatus = (status) => {
		if (status === "Completed") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-F27900'
					style={{ borderRadius: "10px 0 10px" }}>
					<p className='text-F27900'>{ekkLanguage.task.completed}</p>
				</div>
			);
		} else if (status === "Active") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-success'
					style={{ borderRadius: "10px 0 10px" }}>
					<p className='text-success'>{ekkLanguage.task.onGoing}</p>
				</div>
			);
		} else if (status === "Cancelled") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-primary'
					style={{ borderRadius: "10px 0 10px" }}>
					<p className='text-primary'>{ekkLanguage.task.cancelled}</p>
				</div>
			);
		} else {
			return "";
		}
	};

	return (
		<div
			onClick={() => navigate(`/task-details-public/${item?.id}`)}
			className='bg-white overflow-hidden rounded-[10px] cursor-pointer relative'
			style={{ boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)" }}>
			{checkStatus(item?.status)}

			{item?.images?.length ? (
				<div className='h-[250px] overflow-hidden'>
					<img
						src={item?.images[0]}
						alt='blogImage'
						className='object-cover w-full h-full'
					/>
				</div>
			) : (
				<div className='h-[250px] overflow-hidden flex'>
					<img
						src='/assets/images/logo_icon.png'
						alt='blogImage'
						className='m-auto w-[200px] object-cover'
					/>
				</div>
			)}

			<div className='pt-4 px-5 pb-4'>
				<h5 className='text-14 sm:text-18 lg:text-24 font-bold text-262626-1000 pb-[10px] h-[45px] md:h-[76px]'>
					{item.title?.length > 30
						? item.title?.substring(0, 35) + "..."
						: item?.title}
				</h5>

				<div className='flex justify-between py-4'>
					<div>
						<div className='flex items-center'>
							<h6 className='font-bold text-808080 text-12'>
								{ekkLanguage.task.taskStart}:{" "}
							</h6>
							<div>
								<span className='text-black-1000 font-semibold text-12'>
									{" "}
									{digitChanger(
										monthChanger(
											moment(item?.start_date).format("DD MMM, YY")
										)
									)}
								</span>
							</div>
						</div>{" "}
						<div className='flex items-center'>
							<h6 className='font-bold text-12 text-808080'>
								{ekkLanguage.task.taskEnd}:{" "}
							</h6>
							<div>
								<span className='text-black-1000 font-semibold text-12'>
									{" "}
									{digitChanger(
										monthChanger(
											moment(item?.end_date).format("DD MMM, YY")
										)
									)}
								</span>
							</div>
						</div>
					</div>
					<div>
						<div className='flex items-center'>
							<h6 className='font-bold text-12 text-808080'>
								{ekkLanguage.task.position}:{" "}
							</h6>
							<div>
								<span className='text-black-1000 font-semibold text-12'>
									{" "}
									{digitChanger(item?.required_volunteer)}
								</span>
							</div>
						</div>
						<div className='flex items-center'>
							<h6 className='font-bold text-12 text-808080'>
								{ekkLanguage.task.jointed}:{" "}
							</h6>
							<div>
								<span className='text-black-1000 font-semibold text-12'>
									{" "}
									{digitChanger(item?.enrolled_volunteer)}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className='flex justify-between'>
					<p className='text-12 sm:text-16 font-normal text-808080'>
						{content}
					</p>
					<div>
						<span
							className='text-12 sm:text-16 font-normal text-delivered underline'
							href={`#!`}>
							{ekkLanguage.task.readMore}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
